import React, { useCallback, useContext, useMemo } from 'react';

import { ProfileCard } from '../../../commons/profile_card/profile_card';
import { ContactsFront } from './contacts_front/contacts_front';
import { ContactsBack } from './contacts_back/contacts_back';
import { ContactsEditDialog } from './contacts_edit_dialog/contacts_edit_dialog';

import { contactsValidator, validateContactsComplete } from './data/validator';

import { mapContactsFromJsonResume, mapContactsToJsonResume } from './data/mapping';
import { DeveloperProfileContext } from '../../../../utils/context/contexts';
import { SIDES } from '../../../commons/profile_card/profile_card_side/side';
import { useMode } from '../../../hooks/use_mode';

const ContactsCardComponent = ({ variant, side }) => {
    const [mode] = useMode();
    const { data, isEditing, onEdit } = useContext(DeveloperProfileContext);
    const mappedData = useMemo(() => mapContactsFromJsonResume(data), [data]);

    const onDialogEdited = useCallback(
        (editedData) => {
            onEdit(mapContactsToJsonResume(editedData));
        },
        [onEdit]
    );

    const isComplete = useMemo(() => validateContactsComplete(mappedData), [mappedData]);

    const currentSide = useMemo(() => {
        if (!isComplete && !isEditing) {
            return SIDES.FRONT;
        }
        return side;
    }, [side, isComplete, isEditing]);

    if (!isComplete && mode !== 'edit') {
        return null;
    }
    return (
        <ProfileCard
            kind="contacts"
            isEditingProfile={isEditing}
            isComplete={isComplete}
            data={mappedData}
            sides={{
                front: (props) => <ContactsFront {...props} />,
                back: (props) => <ContactsBack {...props} />
            }}
            editDialog={{
                component: ContactsEditDialog,
                validationSchema: contactsValidator,
                onEdit: onDialogEdited
            }}
            variant={variant}
            side={currentSide}
        />
    );
};

export const ContactsCard = ContactsCardComponent;
