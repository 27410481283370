import { createUseStyles } from 'react-jss';

import { styles } from './projects_sides_commons_styles';

const useStyles = createUseStyles(styles);

export const ProjectsSidesCommons = ({ underLayer, children, classes: receivedClasses = {} }) => {
    const classes = useStyles({ classes: receivedClasses });
    return (
        <div className={classes.container}>
            <div className={classes.underLayerContainer}>{underLayer}</div>
            {children}
        </div>
    );
};
