import React, { memo } from 'react';
import { useTheme } from 'react-jss';
import { SocialIcon } from 'react-social-icons';
import { getHexFromPaletteColor } from '../../utils/styles/styles_utils';

const FooterComponent = ({data}) => {
    const iconHeight = 50;
    const iconMargin = '1.0rem';
    const theme = useTheme();
    const mainColor = getHexFromPaletteColor(theme, 'primary');

    return (
        <div className={'w-full pb-20 px-7 mt-20 flex flex-col justify-center md:flex-row text-white'} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: mainColor, height: '150px', marginTop:'0.0rem'}}>
            <div className={'flex flex-col text-sm text-white mt-10 mr-20 md:mr-10 lg:mr-20'} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight:'0px', marginTop:'6.0rem'}}>
                <div className={'flex-1 flex flex-col-reverse mb-2.5'} style={{ display: 'flex', marginTop: '0.0rem' }}>
                    <div className={'flex text-primary-50'}>
                        {(data?.contacts ?? []).map(({ contactUrl, contactImageUrl, name, contactRedirectUrl }) => (
                            <SocialIcon key={name} label={name} url={contactUrl ?? contactRedirectUrl} network={name.toLowerCase()} fgColor="#ffffff" style={{ height: iconHeight, width: iconHeight, marginLeft: iconMargin, marginRight: iconMargin }} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Footer = memo(FooterComponent);