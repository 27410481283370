import axios from "axios";
import Swal from 'sweetalert2';
import { AppConfig } from "../../data/Config";

class EmailService {
  constructor() {
    let service = axios.create({
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    console.log(error);
    this.FireError();
    return Promise.reject(error);
  }

  async FireError(){
    Swal.fire({
          icon: 'error',
          title: 'Erro ao enviar email, tente novamente!',
          timer: 3000
        });
    }

  async SendEmail(payload, callback) {
    const response = await this.service.request({
      method: 'POST',
      url: `${AppConfig.BackendApiUrl}/Email?reCaptcha=${payload.ReCaptcha}`,
      responseType: 'json',
      data: payload,
      headers: { Authorization: `Bearer ${localStorage.getItem('AccessToken')}`, ReCaptcha: payload.ReCaptcha, 'Content-Type': 'application/json'}
    });
    return callback(response.status, response.data);
  }
}

export default new EmailService();