import { Dialog } from '@material-ui/core';
import { Button } from 'semantic-ui-react';
import './config_dialog.css';
import 'semantic-ui-css/semantic.min.css';
import { FormattedMessage } from 'react-intl';
import UserDataService from '../../../services/UserDataService';

const ConfigDialogComponent = ({ open, onClose, locale}) => {
    return (
        <Dialog
            fullScreen={true}
            open={open}
            onClose={onClose}
            style={{margin:"0px", padding:"0px"}}
        >
            <div>
                <div className="ButtonDiv">
                    <Button onClick={onClose} type="button" color="grey" style={{ float:"right" }}>
                        <FormattedMessage id="Main.Lang.Close" />
                    </Button>
                </div>
                <pre>{JSON.stringify(UserDataService.ShowUserDataJson(locale), null, 2)}</pre>
            </div>
        </Dialog>
    );
};

export const ConfigDialog = ConfigDialogComponent;