import { createUseStyles, useTheme } from 'react-jss';

import { Typography } from '@welovedevs/ui';

import { styles } from './project_dialog_content_tags_styles';
import { Button } from '@material-ui/core';
import { TagsField } from './project_dialog_edit_content_tags';
import { getHexFromPaletteColor } from '../../../../../../utils/styles/styles_utils';

import { useFormikContext } from 'formik';
import { useCallback } from 'react';

import './tags_button.css';

const useStyles = createUseStyles(styles);

const ProjectDialogContentTagsComponent = ({ tags, isEditing }) => {
    const classes = useStyles({ isEditing });
    const theme = useTheme();
    const color = getHexFromPaletteColor(theme, 'primary');
    return (
        <div className={classes.container}>
            <Content tags={tags} isEditing={isEditing} classes={classes} color={color}/>
        </div>
    );
};

const Content = ({ tags, isEditing, classes, color }) => {
    if (isEditing) {
        return <EditingContent tags={tags} classes={classes}/>;
    }
    return <DefaultContent tags={tags} classes={classes} color={color} />;
};

const EditingContent = ({ tags, isEditing, classes }) => {
    const { setFieldValue, values, errors } = useFormikContext();
    const handleTagsChange = useCallback(
        (value) => {
            setFieldValue('tags', value);
        },
        [JSON.stringify(values)]
    );

    return(<TagsField
            onChange={handleTagsChange}
            error={errors?.tags}
            tags={tags}
            isEditing={isEditing}
    />);
};

const DefaultContent = ({ tags, classes, color}) => (
    <Typography classes={{ container: classes.typography }}>{tags.map(tag => 
        <Button key={tag.id} className="TagButton" style={{ backgroundColor: color}}>{tag.name}</Button>)}</Typography>
);

export const ProjectDialogContentTags = ProjectDialogContentTagsComponent;