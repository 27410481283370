import {
    createScreenWidthMediaQuery,
    flex,
    getColorsFromCardVariant,
    getHexFromPaletteColor,
    withCustomVerticalScrollbar
} from '../../../../../utils/styles/styles_utils';

const { center } = flex;

export const styles = (theme) => {
    const {
        screenSizes,
        components: {
            cards: { height }
        },
        miscellaneous: { spacing }
    } = theme;
    const { palette } = theme;
    return {
        image: {
            height,
            width: '100%',
            objectFit: 'cover'
        },
        slideName: {
            zIndex: 4,
            position: 'absolute',
            width: '100%',
            bottom: 80,
            left: 0,
            textAlign: 'center'
        },
        transitioningItemWithoutProject: {
            height: '100%',
            width: '100%',
            padding: [spacing * 12, spacing * 14],
            zIndex: 4,
            position: 'absolute',
            top: 0,
            left: 0,
            ...center
        },
        slideNameWithoutProject: {
            maxHeight: '100%',
            overflow: 'auto',
            paddingRight: spacing,
            lineHeight: 'initial',
            ...withCustomVerticalScrollbar('#fff')
        },
        slidesContainer: ({ variant }) => ({
            height: '100%',
            '& .slick-slider': {
                height: '100%'
            },
            '& .slick-dots': {
                zIndex: 3,
                bottom: -10,
                //backgroundColor: getHexFromPaletteColor(theme, getColorsFromCardVariant(theme, variant).backgroundColor),
                '& .slick-active':{
                    '& > button': {
                    //backgroundColor: getHexFromPaletteColor(theme, getColorsFromCardVariant(theme, variant).color)
                }
                }
            }
        }),
        reverseArrow: {
            '& > svg': {
                transform: 'scale(-1)'
            }
        },
        nextArrow: {
            right: 10
        },
        prevArrow: {
            left: 10
        },
        arrow: ({ variant }) => ({
            zIndex: 3,
            top: 'calc(50% - 25px)',
            position: 'absolute',
            backgroundColor: getHexFromPaletteColor(theme, getColorsFromCardVariant(theme, variant).color),
            height: 50,
            width: 50,
            cursor: 'pointer',
            borderRadius: '50%',
            ...center,
            '& > svg': {
                height: 20,
                width: 'auto',
                '& > path': {
                    stroke: getHexFromPaletteColor(theme, getColorsFromCardVariant(theme, variant).backgroundColor)
                }
            }
        }),
        scroll:  ({ variant }) => ({
            height: "470px",
            overflowY: 'scroll',
            overflowX: 'hidden',
            ...withCustomVerticalScrollbar(getHexFromPaletteColor(theme, getColorsFromCardVariant(theme, variant).color))
        }),
        [createScreenWidthMediaQuery('max-width', screenSizes.xs)]: {
            slidesContainer: {
                '& .slick-dots': {
                    display: ['none', '!important']
                }
            },
            slideName: {
                bottom: 35
            }
        },
        [createScreenWidthMediaQuery('max-width', screenSizes.xs)]: {
            scroll:{height: "380px"}
        },
        [createScreenWidthMediaQuery('max-width', screenSizes.small)]: {
            scroll:{height: "435px"}
        },
        title: {
            position: 'relative',
            '& > *:not($background)': {
                zIndex: 2,
                position: 'relative'
            }
        },
        typography: {
            color: [palette.light[500], '!important']
        },
        background: {
            zIndex: 0,
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            overflowY: 'scroll',
            ...center,
            '&::after': {
                zIndex: 1,
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                backgroundColor: `rgba(${palette.dark.rgbShades[900].join(', ')}, .4)`,
                content: "''"
            }
        },
        stubBackground: ({ variant }) => ({
            width: '100%',
            height: '100%',
            backgroundColor: getHexFromPaletteColor(theme, getColorsFromCardVariant(theme, variant).color)
        }),
        backgroundImage: {
            height: '190%',
            width: '110%',
            objectFit: 'cover'
        }
    };
};