import { createScreenWidthMediaQuery } from '../../../../utils/styles/styles_utils';

export const styles = (theme) => ({
    icon: {
        transform: 'scale(1.8)',
        marginRight: '0px',
        marginTop: '0px'
    },
    [createScreenWidthMediaQuery('max-width', theme.screenSizes.small)]: {
        icon: {
            marginRight: 'unset'
        }
    }
});
