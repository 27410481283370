import React, { useContext } from 'react';

import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';

import { Typography } from '@welovedevs/ui';

import { ProjectDialog } from '../project_dialog/project_dialog';
import { AnimatedUnderlinedButton } from '../../../../commons/animated_underlined_button/animated_underlined_button';

import { ReactComponent as EyeIcon } from '../../../../../assets/icons/eye.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit.svg';

import { useCallbackOpen } from '../../../../hooks/use_callback_open';

import { styles } from './see_project_detail_styles';
import { DeveloperProfileContext } from '../../../../../utils/context/contexts';
import { ProfileCardContext } from '../../../../commons/profile_card/profile_card';
import { SET_HAS_DIALOG_OPENED } from '../../../../../store/profile_card/profile_card_actions_types';

const useStyles = createUseStyles(styles);

const SeeProjectDetailComponent = ({ color, project }) => {
    const classes = useStyles();
    const { isEditing } = useContext(DeveloperProfileContext);
    const { dispatch } = useContext(ProfileCardContext);
    const [openDialog, setDialogOpened, setDialogClosed] = useCallbackOpen();
    const leaveOpen = window.location.pathname.toLowerCase() === `/Projects/${project.id}`.toLowerCase();

    const openProjectDialog = () => {
        setDialogOpened();
        dispatch({
            type: SET_HAS_DIALOG_OPENED,
            hasDialogOpened: true
        });
    }

    const closeProjectDialog = () => {
        setDialogClosed();
        dispatch({
            type: SET_HAS_DIALOG_OPENED,
            hasDialogOpened: false
        });
      };

    return (
        <>
            <ProjectDialog
                isEditing={isEditing}
                data={project}
                open={openDialog || leaveOpen}
                onClose={() => closeProjectDialog()}
            />
            <AnimatedUnderlinedButton color={color} onClick={() => openProjectDialog()}>
                {!isEditing && <EyeIcon className={classes.icon} />}
                {isEditing && <EditIcon className={classes.fillIcon} />}
                <Typography classes={{ container: classes.detailTypography }} color="primary">
                    {!isEditing && <FormattedMessage id="Projects.details.seemore" defaultMessage="See more" />}
                    {isEditing && <FormattedMessage id="Main.lang.edit" defaultMessage="Edit" />}
                </Typography>
            </AnimatedUnderlinedButton>
        </>
    );
};

export const SeeProjectDetail = SeeProjectDetailComponent;
