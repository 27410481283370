const appDomain = window.location.host.toLowerCase();
const AppEnvSuffix = appDomain.includes("local") ? '_LOCAL' : (appDomain.split(".")[0] === 'qas' || appDomain.includes('qas') ? '_QAS' :'');

export const AppConfig =
{
    BaseAppUrl: process.env['REACT_APP_BASE_APP_URL' + AppEnvSuffix],
    BackendApiUrl: process.env['REACT_APP_BACKEND_URL' + AppEnvSuffix],
    GeoApiKey: process.env['REACT_APP_GEO_API_KEY' + AppEnvSuffix],
    AzureAd:{
        Instance: process.env['REACT_APP_INSTANCE' + AppEnvSuffix],
        ClientId: process.env['REACT_APP_CLIENT_ID' + AppEnvSuffix],
        ClientName: process.env['REACT_APP_CLIENT_NAME' + AppEnvSuffix],
        TenantId: process.env['REACT_APP_TENANT_ID' + AppEnvSuffix]
    },
    PublicRecaptchaKey: process.env['REACT_APP_PUBLIC_RECAPTCHA_KEY' + AppEnvSuffix],
    FirebaseStorage: process.env.REACT_APP_FIREBASESTORAGE
};