import React, { useMemo, useRef, useEffect } from 'react';

import cn from 'classnames';
import { createUseStyles } from 'react-jss';

import { PaletteVisual } from '../palette_visual/palette_visual';
import { ChromePicker } from 'react-color'

import { buildShadedPalette } from './utils/build_shaded_palette';

import { styles } from './palettes_list_styles';

const useStyles = createUseStyles(styles);

const PalettesListComponent = ({ value: currentPalette, onChange, classes: receivedClasses = {} }) => {
    const classes = useStyles();
    const containerReference = useRef();
    const paletteEntries = useMemo(() => Object.entries(createDefaultPallet(currentPalette)), [currentPalette]);

    useEffect(() => {
        let palette = createDefaultPallet(currentPalette);
        onChange({
            primary: palette.primary,
            secondary: palette.secondary,
            tertiary: palette.tertiary
        });
    }, []);

    function onColorChange(color, key){
        let palette = createDefaultPallet(currentPalette);
        palette[key] = buildShadedPalette(color);
        onChange({
            primary: palette.primary,
            secondary: palette.secondary,
            tertiary: palette.tertiary
        });
    }

    function createDefaultPallet(palette) {
        return {
            primary: palette?.primary ?? buildShadedPalette("#0000ff"),
            secondary: palette?.secondary ?? buildShadedPalette("#01879b"),
            tertiary: palette?.tertiary ?? buildShadedPalette("#dddddd")
        }
    };

    return (
        <div
            ref={containerReference}
            id={`scrollable_${classes.container}`}
            className={cn(classes.container, receivedClasses.container)}
        >
            <div className={classes.selectedPaletteContainer}>
                <PaletteVisual
                    palette={createDefaultPallet(currentPalette)}
                    classes={{
                        tooltipPopper: classes.tooltipPopper,
                        color: classes.paletteVisualColor
                    }}
                />
                <div className={classes.divider} />
            </div>
            {paletteEntries.map(([key, { 500: hex }]) => (
                <>
                    <ChromePicker key={`chrome_picker_${key}`} color={hex} onChangeComplete={(color, event) => onColorChange(color.hex, key)}/>
                    <div key={`chrome_picker_separator_${key}`} style={{marginTop: '20px'}}/>
                </>
            ))}
        </div>
    );
};

export const PalettesList = PalettesListComponent;
