import { Button } from '@welovedevs/ui';
import { useState } from 'react';
import {useIntl} from 'react-intl';
import { ContactDialog } from './contact_dialog/contact_dialog';
import EmailService from '../../services/EmailService';
import LogService from '../../services/LogService';
import Swal from 'sweetalert2';

import { ReactComponent as EmailLogo } from '../../assets/icons/email.svg';
import { ReactComponent as WhiteEmailLogo } from '../../assets/icons/brands/social/Icon__Email.svg';

const ContactButton = ({ isButton, locale}) => {
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const [enviando, setEnviando] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    function close(){
        setOpen(false);
        setEnviando(false);
    }

    function setToken(token){
        setRecaptchaToken(token);
    }

    function openContact(){
        setOpen(true);
    }
    async function onSubmit(event){
        setEnviando(true);
        try
        {
            let log = await LogService.LogIp('SendEmail');
            let params = {
                address: event.target.user_email.value,
                name: event.target.user_name.value,
                message: event.target.user_message.value,
                ipv4: '',
                ipv6: '',
                ReCaptcha: recaptchaToken,
                locale: locale
            }
            await EmailService.SendEmail(params, handleCallback);
        }
        catch(e)
        {
            await EmailService.FireError();
        }
        
        close();
    }

    function handleCallback (callback) {
        close();
        switch(callback){
            case 200:
                Swal.fire({
                    icon: 'success',
                    title: intl.formatMessage({id: "EmailForm.SuccessMessage"}),
                    timer: 3000
                    });
            break;
            case 401:
                Swal.fire({
                    icon: 'success',
                    title: intl.formatMessage({id: "EmailForm.ErrorMessage"}),
                    timer: 3000
                    });
            break;
            case 429:
                Swal.fire({
                    icon: 'success',
                    title: intl.formatMessage({id: "EmailForm.TooManyRequestsMessage"}),
                    timer: 3000
                    });
            break;
        }
    }

    return (
        <>
            <ContactDialog
                open={open}
                onClose={close}
                onSubmit={onSubmit}
                enviando={enviando}
                setToken={setToken}
                locale={locale === "br" ? "pt-BR": locale}
            />
            {isButton === true ?
                <Button style={{ padding: '0' }} className="dialogButton" color={'light'} onClick={openContact}>
                    {<EmailLogo />}
                </Button> :
                <a href={'#bottom'} onClick={openContact}>
                <WhiteEmailLogo className={'w-10'} />
            </a>}
        </>
    );
};

export {ContactButton};