import { useCallback, useContext } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Button } from '@welovedevs/ui';

import { DeveloperProfileContext } from '../../../../utils/context/contexts';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/success.svg';
import { styles } from './edit_button_styles';

const useStyles = createUseStyles(styles);

export const EditButton = () => {
    const { isEditing, setIsEditing } = useContext(DeveloperProfileContext);
    const theme = useTheme();
    const classes = useStyles(theme);

    const handleClick = useCallback(() => setIsEditing(!isEditing), [isEditing]);

    if (!isEditing) {
        return (
            <Button style={{ background:'white', height:'41px', width:'72px' }} color="light" onClick={handleClick}>
                <EditIcon className={classes.penIconFill} />
            </Button>
        );
    }
    return (
        <Button style={{ background:'white', height:'41px', width:'72px' }} color="light" onClick={handleClick}>
            <CheckIcon className={classes.checkIconFill} />
        </Button>
    );
};
