import { Button } from '@welovedevs/ui';
import { ReactComponent as PdfLogo } from '../../assets/icons/pdf.svg';
import LogService from '../../services/LogService';
import { AppConfig } from '../../../data/Config';

const ResumeButton = ({ locale }) => {
    async function openFile() {
        let upperLocale = locale.charAt(0).toUpperCase() + locale.charAt(1);
        await LogService.LogIp(`ViewResume${upperLocale}`);
        window.open(`${AppConfig.BackendApiUrl}/Files/Resume?lang=${locale}`);
    }
    
    return (
        <>
            <Button style={{padding:'0'}} color={'light'} onClick={openFile}>
                {<PdfLogo color={'red'} />}
            </Button>
        </>
    );
};

export {ResumeButton};