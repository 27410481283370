import React from 'react';

import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';

import { ProfileCardTitle } from '../../../../commons/profile_card/profile_card_title/profile_card_title';

import { styles } from './contacts_sides_commons_styles';
import { ContactAuthorCredits } from '../../../../commons/contacts/contact_author_credits/contact_author_credits';

const useStyles = createUseStyles(styles);

export const ContactsSidesCommons = ({ underLayer, children, contactRedirectUrl, contactUrl, contactUser, name, classes: receivedClasses = {} }) => {
    const classes = useStyles({ classes: receivedClasses });
    return (
        <div className={classes.container}>
            {underLayer && (
                <a href={contactUrl ?? contactRedirectUrl} target="_blank">
                    <div className={classes.underLayerContainer}>{underLayer}</div>
                    <div className={classes.darkenLayer} />
                </a>
            )}
            <ProfileCardTitle
                overrideColor="light"
                classes={{
                    container: classes.title
                }}
            >
                { name ?? <FormattedMessage id="Contacts.side.commons" defaultMessage="Contacts" /> }
            </ProfileCardTitle>
            {children}
            {contactUser && <ContactAuthorCredits classes={{ container: classes.credits }} user={contactUser} />}
        </div>
    );
};
