import { Button } from '@welovedevs/ui';
import { useState } from 'react';
import { ConfigDialog } from './config_dialog/config_dialog';

import { ReactComponent as ConfigLogo } from '../../assets/icons/config.svg';

const ConfigButton = ({ locale }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <ConfigDialog
                open={open}
                onClose={() => setOpen(false)}
                locale={locale}
            />
                <Button style={{ padding: '0', background:'white' }} className="configButton" color={'light'} onClick={() => setOpen(true)}>
                    {<ConfigLogo />}
                </Button>
            </>
    );
};

export {ConfigButton};