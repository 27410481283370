import axios from "axios";
import { AppConfig } from "../../data/Config";

class LogService {
  constructor() {
    let service = axios.create({
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  async LogIp(action) {
      try {
          const payload = await this.GetBrowserData();
          payload.Action = action;
          await this.service.request({
              method: 'POST',
              url: `${AppConfig.BackendApiUrl}/Log`,
              responseType: 'json',
              data: payload
          });
          return payload;
      }
      catch (e) {
          console.log(e);
          return {};
      }
  }

  async GetBrowserData() {
    return {
        BrowserPlataform: window.navigator.platform,
        BrowserName: window.navigator.appName,
        BrowserCodeName: window.navigator.CodeName,
        BrowserEngine: window.navigator.product,
        BrowserLanguage: window.navigator.language,
        Action: ''
    }
  }
}

export default new LogService();