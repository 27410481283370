import { defineMessages } from 'react-intl';

export const translations = defineMessages({
    contactNamePlaceholder: {
        id: 'Contacts.Dialog.hobbies.name.placeholder',
        defaultMessage: 'ex: WhatsApp, Telegram...'
    },
    contactImageUrlPlaceholder: {
        id: 'Contacts.Dialog.hobbies.name.placeholder',
        defaultMessage: 'Contact Image Url'
    },
    contactUrlPlaceholder: {
        id: 'Contacts.Dialog.hobbies.name.placeholder',
        defaultMessage: 'Contact Redirect Url'
    },
    contactViewUrlPlaceholder: {
        id: 'Contacts.Dialog.hobbies.name.placeholder',
        defaultMessage: 'Contact Url'
    }
});
