import { Dialog } from '@material-ui/core';
import { Form, Input, TextArea, Button } from 'semantic-ui-react';
import './contact_dialog.css';
import 'semantic-ui-css/semantic.min.css';
import {FormattedMessage, useIntl} from 'react-intl';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef, useState } from 'react';
import { AppConfig } from '../../../../data/Config';

const ContactDialogComponent = ({ open, onClose, onSubmit, enviando, setToken, locale}) => {
    const intl = useIntl();
    const fullScreen = true;
    const isMobile = false;
    const [sendButtonEnabled, setSendButtonEnabled] = useState(false);
    const recaptchaRef = useRef();

    function verifyCallback(token){
        setSendButtonEnabled(true);
        setToken(token);
    }

    function handleSubmit(event){
        setSendButtonEnabled(false);
        onSubmit(event);
    }
    
    return (
        <Dialog
            fullScreen={fullScreen || isMobile}
            open={open}
            onClose={onClose}
            style={{margin:"0px", padding:"0px"}}
        >
            <div className="ContactForm" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundColor:"#d8d8d8"}}>
                <Form onSubmit={handleSubmit} loading={enviando} style={{ alignItems: 'center', backgroundColor:"#d8d8d8"}}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop:"20px"}}>
                        <Form.Field
                        className="ContactField"
                        id="form-input-control-email"
                        control={Input}
                        label={intl.formatMessage({id: "EmailForm.Email"})}
                        name="user_email"
                        placeholder={intl.formatMessage({id: "EmailForm.Email"}) + "..."}
                        required
                        icon="mail"
                        iconPosition="left"
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Form.Field
                        className="ContactField"
                        id="form-input-control-name"
                        control={Input}
                        label={intl.formatMessage({id: "EmailForm.Name"})}
                        name="user_name"
                        placeholder={intl.formatMessage({id: "EmailForm.Name"}) + "..."}
                        required
                        icon="user circle"
                        iconPosition="left"
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom:"30px"}}>
                        <Form.Field
                        className="ContactFieldLarge"
                        id="form-input-control-message"
                        control={TextArea}
                        label={intl.formatMessage({id: "EmailForm.Message"})}
                        name="user_message"
                        placeholder={intl.formatMessage({id: "EmailForm.Message"}) + "..."}
                        required
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:"0px", padding:"0px"}}>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="normal"
                            render="explicit"
                            sitekey={AppConfig.PublicRecaptchaKey}
                            onChange={verifyCallback}
                            hl={locale}
                        />
                    </div>
                    <div className="ButtonDiv">
                        <Button disabled={enviando} onClick={onClose} color="grey" style={{marginRight:"30px"}}>
                            <FormattedMessage id="EmailForm.Cancel" />
                        </Button>
                        <Button disabled={!sendButtonEnabled || enviando} type="submit" color="blue">
                            {enviando?
                                intl.formatMessage({id: "EmailForm.Sending"}):
                                intl.formatMessage({id: "EmailForm.Send"})
                            }
                        </Button>
                    </div>
                </Form>
            </div>
        </Dialog>
    );
};

export const ContactDialog = ContactDialogComponent;
