import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { Tag, Tooltip, Typography } from '@welovedevs/ui';

import { LocationField } from '../../../../../commons/location_field/location_field';
import { EditDialogField } from '../../../../../commons/edit_dialog_field/edit_dialog_field';

import {
    TAGS_FIELD_TRANSITIONS_PROPS,
    TAGS_LIST_TRANSITION_PROPS
} from './tags_field_transitions_props';

import { ReactComponent as TrashIcon } from '../../../../../../assets/icons/trash.svg';

import { styles } from './project_dialog_content_tags_styles';

import { ReactComponent as MoveIcon } from '../../../../../../assets/icons/move.svg';
import { DEFAULT_SPRING_TYPE as spring } from '../../../../../../utils/framer_motion/common_types/spring_type';
import { AnimatePresence, motion } from 'framer-motion';
import uuid from 'uuid/v4';

import React, { useCallback, useMemo } from 'react';

const useStyles = createUseStyles(styles);
const DragHandle = SortableHandle(({ classes }) => (
    <button className={classes.dragHandleButton} type="button">
        <MoveIcon className={classes.dragHandle} />
    </button>
));
const SortableTag = SortableElement(({ onRemove, item, motionConfig, isEditing}) => {
    const classes = useStyles({ isEditing });

    return (
        <Tag
            key={`${item.id}_${item.name}`}
            component={motion.div}
            classes={{ container: classes.tag }}
            color="secondary"
            {...motionConfig}
        >
            <DragHandle classes={classes} />
            <Tooltip
                title={<FormattedMessage id="Projects.editDialog.tags.delete" defaultMessage="Delete this technology" />}
            >
                <button type="button" onClick={onRemove}>
                    <TrashIcon className={classes.deleteIcon} />
                </button>
            </Tooltip>
            <Typography variant="body2" color="light">
                {item.name}
            </Typography>
        </Tag>
    );
});
const SortableTags = SortableContainer(({ onRemove, items, isEditing }) => {
    const classes = useStyles({ isEditing });

    return (
        <motion.div
            variants={TAGS_LIST_TRANSITION_PROPS}
            initial="initial"
            animate="animate"
            exit="exit"
            className={classes.tags}
        >
            <AnimatePresence>
                {items.map(
                    (item, index) =>
                        item && (
                            <SortableTag
                                index={index}
                                key={`tag_${item.id}`}
                                item={item}
                                motionConfig={{
                                    variants: TAGS_FIELD_TRANSITIONS_PROPS,
                                    transition: spring
                                }}
                                onRemove={() => {onRemove(item.id)}}
                                isEditing={isEditing}
                            />
                        )
                )}
            </AnimatePresence>
        </motion.div>
    );
});
const TagsComponent = ({ tags, isEditing, onChange}) => {
    const classes = useStyles({ isEditing });
    const tagsValues = useMemo(() => Object.values(tags || []), [tags]);

    const onMove = useCallback(
        ({ oldIndex, newIndex }) => {
            onChange(arrayMove(tagsValues, oldIndex, newIndex).map((item, index) => ({ ...item, index })));
        }
    );

    const addTag = useCallback((tag) =>  {
            onChange(((tags || []).concat({ ...tag, id: uuid() })));
        }
    );

    const handleRemoveTag = useCallback((id) => {
            onChange(tags.filter((tag) => tag.id !== id));
        }
    );

    return (
        <EditDialogField
            title={
                <FormattedMessage
                    id="Projects.editDialog.tags.title"
                    defaultMessage="Which technologies were used in this project?"
                />
            }
        >
            <LocationField
                fullWidth
                classes={{
                    container: classes.tagField
                }}
                clearOnSelect
                variant="flat"
                onLocationSelected={addTag}
            />
            <SortableTags
                lockToContainerEdges
                axis="xy"
                helperClass={classes.sortableHelper}
                items={tagsValues}
                onRemove={handleRemoveTag}
                distance={15}
                // onSortStart={handleSortStart}
                onSortEnd={onMove}
                classes={classes}
                isEditing={isEditing}
            />
        </EditDialogField>
    );
};

export const TagsField = TagsComponent;