import { secondary } from '@welovedevs/ui/styles';

export const styles = (theme) => {
    const {
        miscellaneous: { spacing }
    } = theme;
    return {
        container: ({ isEditing }) => ({
            marginBottom: spacing * 4,
            ...(!isEditing && {
                textAlign: 'center'
            })
        }),
        typography: {
            whiteSpace: 'pre-line',
            lineHeight: '24px'
        },
        tagField: {
            marginBottom: spacing * 3
        },
        tags: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        tag: {
            transformOrigin: 'center left',
            color: secondary[500]

        },
        deleteIcon: {
            fill: '#fff',
            width: 24,
            height: 24,
            marginRight: spacing,
            cursor: 'pointer'
        },
        sortableHelper: {
            zIndex: 10000000000
        },
        dragHandleButton: {
            display: 'flex'
        },
        dragHandle: {
            marginRight: spacing,
            width: 18,
            height: 18,
            '& g': {
                stroke: 'white'
            }
        }
    };
};