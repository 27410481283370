import React, { useCallback, useContext, useMemo } from 'react';

import { useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';

import { useFormikContext } from 'formik';

import { ProjectDialogContentTitle } from './project_dialog_content_title/project_dialog_content_title';
import { ProjectDialogContentImages } from './project_dialog_content_images/project_dialog_content_images';
import { ProjectDialogContentDescription } from './project_dialog_content_description/project_dialog_content_description';
import { ProjectDialogContentTags } from './project_dialog_content_tags/project_dialog_content_tags';
import { ProjectDialogContentDate } from './project_dialog_content_date/project_dialog_content_date';
import { ProjectDialogContentId } from './project_dialog_content_id/project_dialog_content_id';

import { styles } from './project_dialog_styles';
import { EditDialog } from '../../../../commons/edit_dialog/edit_dialog';
import { ProjectValidator } from '../data/validator';
import { mapProjectToJsonResume, updateProjectsArray } from '../data/mapping';
import { ProjectDialogContentLink } from './project_dialog_content_link/project_dialog_content_link';
import { DeveloperProfileContext } from '../../../../../utils/context/contexts';
import { AppConfig } from '../../../../../../data/Config';

const useStyles = createUseStyles(styles);

const DEFAULT_OBJECT = {};
const ProjectDialogComponent = ({ open, onClose, data: project, isEditing }) => {
    const classes = useStyles();

    const { formatMessage } = useIntl();
    const { onEdit, data: resume } = useContext(DeveloperProfileContext);

    const onDialogEdited = useCallback(
        (editedData) => {
            const newProject = mapProjectToJsonResume(editedData);
            const newProjectArray = updateProjectsArray(newProject, resume);
            onEdit(newProjectArray);
            onClose();
        },
        [onEdit, resume]
    );

    const validator = useMemo(() => ProjectValidator(formatMessage), []);
    const leaveOpen = window.location.pathname.toLowerCase() === `/Projects/${project.id}`.toLowerCase();

    function closeAndRedirect()
    {
        if(leaveOpen){
            window.location.replace(AppConfig.BaseAppUrl);
        }
        onClose();
    }
    
    return (
        <EditDialog
            classes={{ content: classes.container, paper: classes.paper }}
            fullScreen={true}
            open={open || leaveOpen}
            onClose={()=> closeAndRedirect()}
            data={project || DEFAULT_OBJECT}
            onEdit={onDialogEdited}
            validationSchema={validator}
            isEditing={isEditing}
            title={project.name}
        >
            {() => <ProjectDialogContent isEditing={isEditing} />}
        </EditDialog>
    );
};

const ProjectDialogContent = ({ isEditing }) => {
    const classes = useStyles();

    const { values: project } = useFormikContext();
    return (
        <>
            <div className={classes.headrow}>
                <ProjectDialogContentTitle isEditing={isEditing} title={project.title} />
                <ProjectDialogContentId isEditing={isEditing} id={project.id} />
                <ProjectDialogContentDate isEditing={isEditing} date={project.data} />
            </div>
            <ProjectDialogContentTags isEditing={isEditing} tags={project.tags} />
            <ProjectDialogContentDescription isEditing={isEditing} description={project.description} />
            <ProjectDialogContentImages isEditing={isEditing} images={project.images} id={project.id} />
            <ProjectDialogContentLink isEditing={isEditing} link={project.link} />
        </>
    );
};

export const ProjectDialog = ProjectDialogComponent;
