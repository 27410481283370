import { useIntl } from 'react-intl';

import { createUseStyles } from 'react-jss';

import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';

import { BouncingRoundButton } from '../../bouncing_round_button/bouncing_round_button';

import { styles } from './profile_card_edit_button_styles';

const useStyles = createUseStyles(styles);

const ProfileCardEditButtonComponent = ({ title, setEditDialogOpened }) => {
    const classes = useStyles();
    const intl = useIntl();
    return (
        <BouncingRoundButton
            title={title ?? intl.formatMessage({id: "Main.lang.editCard"})}
            icon={EditIcon}
            onClick={setEditDialogOpened}
            classes={{
                container: classes.container
            }}
        />
    );
};

export const ProfileCardEditButton = ProfileCardEditButtonComponent;
