import en from '../i18n/en.json';
import br from '../i18n/br.json';

import axios from 'axios';
import Swal from 'sweetalert2';
import { AppConfig } from "../../data/Config";

import UserDataBR from '../../data/UserDataBR.json';
import UserDataEN from '../../data/UserDataEN.json';

const Constants = {
  ApiUrl: '__BACKEND_API_URL__'
};

const UserData = {
    br: JSON.parse(JSON.stringify(UserDataBR).replaceAll(Constants.ApiUrl, AppConfig.BackendApiUrl)),
    en: JSON.parse(JSON.stringify(UserDataEN).replaceAll(Constants.ApiUrl, AppConfig.BackendApiUrl))
};

const Messages = {
    en,
    br
};

const TokenRequest = {
  scopes: [AppConfig.AzureAd.ClientId + "/.default"]
};

const BuildRequestConfig = (token) => {
  return { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'} }
};

class UserDataService {
  constructor() {
    let service = axios.create({
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  ErrorPopup(){
    Swal.fire({ icon: 'error', timer: 3000 });
  }

  async GetApiData(locale){
    var response = await axios.get(`${AppConfig.BackendApiUrl}/User?lang=${locale}`);
    return response?.data ?? UserData[locale];
  }
  
  async SetApiData(MsalAuthProvider, data, customization, locale)
  {
    await MsalAuthProvider.acquireTokenPopup(TokenRequest).then(token => {
      localStorage.setItem('AccessToken', token.accessToken);
      let body = data;
      body.resumeCustomization = customization;
      
      let lastUpdatedAt = new Date().getTime();

      if(lastUpdatedAt >= body.lastUpdatedAt)
        body.lastUpdatedAt = lastUpdatedAt;

      axios.post(`${AppConfig.BackendApiUrl}/User?lang=${locale}`, body, BuildRequestConfig(token.accessToken))
      .then((response) => {
          if(response?.data)
              Swal.fire({ icon: 'success', timer: 3000 });
          else this.ErrorPopup();
        })
        .catch((message) => {
            this.ErrorPopup();
            console.log(message);
        });
    });
  }

  SetLanguage(locale){
    localStorage.setItem('UserCurrentLocale', locale);

    if(new URLSearchParams(window.location.search).get('lang'))
      window.location.replace(AppConfig.BaseAppUrl);
  }

  GetMode(){
    if(window.location.pathname === '/admin')
      return 'edit';
    return localStorage.getItem('UserMode') ?? 'readOnly';
  }

  async InitToken(MsalAuthProvider, mode) {
    if (mode === "edit")
      {
          await MsalAuthProvider
              .acquireTokenPopup(TokenRequest)
              .then(token => {
                  localStorage.setItem('AccessToken', token?.accessToken ?? '');
              })
              .catch((message) => {
                  localStorage.setItem('AccessToken', 'Error!');
                  console.log(message);
              });
      }
  }

  InitMode(newMode) {
    let mode = newMode ?? this.GetMode();
    localStorage.setItem('UserMode', mode);
    return mode;
  }

  SetMode(newMode) {
    let mode = newMode ?? this.GetMode()
    let modeUrlSufix = mode === 'edit' ? '/admin' : '';

    window.history.replaceState({}, null, `${AppConfig.BaseAppUrl}${modeUrlSufix}`);
  }

  GetNewModeUrl(mode, shouldInit) {
    let newMode = 'readOnly';
    let modeUrlSufix = '';

    if(mode === 'readOnly'){
      modeUrlSufix = '/Admin';
      newMode = 'edit';
    }

    if(shouldInit)
      this.InitMode(newMode);

    return `${AppConfig.BaseAppUrl}${modeUrlSufix}`;
  }

  GetTokenRequest(){
    return TokenRequest;
  }

  GetLocale(){
    return new URLSearchParams(window.location.search).get('lang') ?? localStorage.getItem('UserCurrentLocale') ?? 'en';
  }

  GetData(locale){
    return UserData[locale ?? this.GetLocale()];
  }

  GetMessages(locale){
    return Messages[locale ?? this.GetLocale()];
  }

  ShowUserDataJson(locale){
    return { config: BuildRequestConfig(localStorage.getItem('AccessToken')), body: this.GetData(locale) };
  }
}

export default new UserDataService();