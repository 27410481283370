import React, { useCallback, useContext, useMemo, useState } from 'react';

import { createUseStyles, useTheme } from 'react-jss';
import { useFormikContext } from 'formik';
import keyBy from 'lodash.keyby';

import omit from 'lodash.omit';
import uuid from 'uuid/v4';
import { arrayMove } from 'react-sortable-hoc';

import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { Typography } from '@welovedevs/ui';

import { SearchContactsDialog } from '../../../../../commons/search_contact_dialog/search_contacts_dialog';
import { AddButtonDashed } from '../../../../../commons/add_button_dashed/add_button_dashed';
import { ContactsSortableCards } from './contacts_sortable_cards/contacts_sortable_cards';

import { styles } from './contacts_edit_form_styles';
import { StaticDataContext } from '../../../../../../utils/context/contexts';
import { URLFallbackDialog } from '../../../../../commons/url_fallback_dialog.jsx/url_fallback_dialog';

const useStyles = createUseStyles(styles);

const ContactsEditFormComponent = ({ helpers: { handleValueChange } }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.screenSizes.small}px)`);
    const classes = useStyles();
    const {
        values: { contacts },
        errors: validationErrors
    } = useFormikContext();

    const { apiKeys } = useContext(StaticDataContext);
    const hasGiphyKey = useMemo(() => !!apiKeys.giphy, [apiKeys.giphy]);

    const [selectedIndex, setSelectedIndex] = useState(null);
    const removeSelectedIndex = useCallback(() => setSelectedIndex(null), []);

    const { contacts: errors } = validationErrors || {};

    const keyedValues = useMemo(() => keyBy(contacts, ({ id }) => id), [JSON.stringify(contacts)]);

    const contactChanged = useCallback((contactIndex, field, value) => {
        handleValueChange(`contacts[${contactIndex}].${field}`)(value);
    }, []);

    const contactDeleted = useCallback(
        (id) => {
            handleValueChange('contacts')(Object.values(omit(keyedValues, id)));
        },
        [JSON.stringify(keyedValues), JSON.stringify(contacts)]
    );

    const addContact = useCallback(() => {
        const id = uuid();
        handleValueChange('contacts')(
            contacts.concat({
                index: contacts.length,
                id
            })
        );

        setSelectedIndex(contacts.length);
    }, [JSON.stringify(contacts)]);

    const move = useCallback(
        ({ oldIndex, newIndex }) => {
            handleValueChange('contacts')(
                arrayMove(contacts, oldIndex, newIndex).map((data, index) => ({ ...data, index }))
            );
        },
        [contacts]
    );

    const globalError = typeof errors === 'string' && errors;

    const handleContactSelection = useCallback(
        ({ name, contactUrl, contactImageUrl, contactRedirectUrl }) => {
            contactChanged(selectedIndex, 'name', name);
            contactChanged(selectedIndex, 'contactUrl', contactUrl);
            contactChanged(selectedIndex, 'contactImageUrl', contactImageUrl);
            contactChanged(selectedIndex, 'contactRedirectUrl', contactRedirectUrl);
            removeSelectedIndex();
        },
        [contactChanged, selectedIndex]
    );
    const handleContactChange = useCallback(
        (url) => {
            contactChanged(selectedIndex, 'contactImageUrl', url);
        },
        [contactChanged, selectedIndex]
    );

    return (
        <>
            {hasGiphyKey && (
                <SearchContactsDialog
                    open={Boolean(selectedIndex !== null)}
                    onClose={removeSelectedIndex}
                    onSelect={handleContactSelection}
                />
            )}
            {!hasGiphyKey && (
                <URLFallbackDialog
                    open={Boolean(selectedIndex !== null)}
                    onClose={removeSelectedIndex}
                    onChange={handleContactChange}
                />
            )}
            {globalError && (
                <Typography color="danger" variant="h4" component="h4">
                    {globalError}
                </Typography>
            )}
            {isMobile && (
                <AddButtonDashed
                    classes={{
                        container: classes.addButtonDashed
                    }}
                    onClick={addContact}
                />
            )}
            <ContactsSortableCards
                items={contacts}
                contactDeleted={contactDeleted}
                contactChanged={contactChanged}
                errors={errors}
                onSortEnd={move}
                setSelectedIndex={setSelectedIndex}
            />
            {!isMobile && (
                <AddButtonDashed
                    classes={{
                        container: classes.addButtonDashed
                    }}
                    onClick={addContact}
                />
            )}
        </>
    );
};

export const ContactsEditForm = ContactsEditFormComponent;
