import { memo, useCallback, useContext, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import { createUseStyles, useTheme } from 'react-jss';
import Slider from 'react-slick';

import { ProfileCardContent } from '../../../../commons/profile_card/profile_card_content/profile_card_content';
import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/arrow-right.svg';
import { ProjectSection } from './project_section/project_section';
import { styles } from './projects_back_styles';
import { useCardVariant } from '../../../../hooks/profile_card_hooks/use_card_variant';
import { DeveloperProfileContext } from '../../../../../utils/context/contexts';
import { ProjectsSidesCommons } from '../projects_side_commons/projects_sides_commons';
import { existsAndNotEmpty } from '../../../utils/exists_and_not_empty';
import { AnimatePresence, motion } from 'framer-motion';
import { DEFAULT_SPRING_TYPE as spring } from '../../../../../utils/framer_motion/common_types/spring_type';

import { NoProject } from './no_project/no_project';

const useStyles = createUseStyles(styles);

const PROJECTS_BACK_TRANSITIONS_PROPS = {
    toTheLeft: {
        opacity: 0,
        x: '25%'
    },
    center: {
        opacity: 1,
        x: 0
    },
    toTheRight: {
        opacity: 0,
        x: '-25%'
    }
};

const SETTINGS = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1
};

const ProjectsBackComponent = ({ data, handleAddButtonClick}) => {
    const [variant] = useCardVariant();
    const classes = useStyles({ variant });
    const { onEdit } = useContext(DeveloperProfileContext);
    const handleProjectDeletion = useCallback(
        (index) => {
            const newProjects = [...data.projects];
            newProjects.splice(index, 1);
            onEdit({ projects: newProjects });
        },
        [data, onEdit]
    );

    const [previousIndex, setPreviousIndex] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleBeforeChange = useCallback((current, next) => {
        setPreviousIndex(current);
        setCurrentIndex(next);
    }, []);

    const sliderReference = useRef();

    const pauseSlider = useCallback(() => sliderReference.current?.slickPause(), []);

    const resumeSlider = useCallback(() => sliderReference.current?.slickPlay(), []);

    return (
        <ProjectsSidesCommons
            classes={{ classes }}
            underLayer={
                <div id='projectUnderlayerDiv' className={classes.slidesContainer}>
                    <Slider
                        {...SETTINGS}
                        ref={sliderReference}
                        beforeChange={handleBeforeChange}
                        prevArrow={<Arrow classes={classes} arrowRole="prev" />}
                        nextArrow={
                            <Arrow classes={classes} arrowRole="next" buttonProps={{ className: classes.nextButton }} />
                        }
                    >
                        {data.projects?.map((project) => (
                            <div className={classes.scroll} key={`profile_card_div_${project.id}`}>
                                <ProfileCardContent key={`profile_card_${project.id}`}>
                                    <ProjectSection project={project} key={`project_${project.id}`} onDelete={handleProjectDeletion} />
                                </ProfileCardContent>
                            </div>
                        ))}
                    </Slider>
                    {!existsAndNotEmpty(data?.projects) && <NoProject handleAddButtonClick={handleAddButtonClick} />}
                </div>
            }
        >
            <Content
                {...{ data, previousIndex, currentIndex, pauseSlider, resumeSlider, handleAddButtonClick, classes }}
            />
        </ProjectsSidesCommons>
    );
};

const Content = ({ data, pauseSlider, previousIndex, currentIndex, resumeSlider, handleAddButtonClick, classes }) => {
    const hasContact = useMemo(() => existsAndNotEmpty(data?.projects), [data]);

    const item = data.projects?.[currentIndex];

    const animationState = useMemo(() => {
        if (previousIndex === currentIndex) {
            return { initial: 'center' };
        }
        if (previousIndex < currentIndex) {
            return {
                initial: 'toTheLeft',
                animate: 'center',
                exit: 'toTheRight'
            };
        }
        return {
            initial: 'toTheRight',
            animate: 'center',
            exit: 'toTheLeft'
        };
    }, [previousIndex, currentIndex]);

    if (!hasContact) {
        return <NoProject {...{ handleAddButtonClick }} />;
    }

    return (
        item?.name && (
            <TransitioningItem
                key={item.id || `${new Date().getTime()}`}
                item={item}
                classes={classes}
                pauseSlider={pauseSlider}
                resumeSlider={resumeSlider}
                motionConfig={{
                    variants: PROJECTS_BACK_TRANSITIONS_PROPS,
                    ...animationState,
                    transition: spring
                }}
            />
        )
    );
};

const Arrow = ({ classes, onClick, arrowRole }) => (
    <motion.div
        onClick={onClick}
        className={cn(
            classes.arrow,
            arrowRole === 'next' && classes.nextArrow,
            arrowRole === 'prev' && cn(classes.reverseArrow, classes.prevArrow)
        )}
        type="button"
        initial={{ scale: 1 }}
        whileTap={{ scale: 0.9 }}
    >
        <ArrowIcon />
    </motion.div>
);

const TransitioningItem = ({ item, pauseSlider, resumeSlider, classes, motionConfig }) => {
    return (
        <AnimatePresence>
            <motion.div
                {...motionConfig}
                className={classes.transitioningItemWithoutProjects}
                onMouseEnter={pauseSlider}
                onMouseLeave={resumeSlider}
            >
            </motion.div>
        </AnimatePresence>
    );
};

export const ProjectsBack = memo(ProjectsBackComponent);
