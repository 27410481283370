import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';

import { Button, Card, PopperCardActions, TextField, Typography } from '@welovedevs/ui';

import { BouncingRoundButton } from '../../../../../../commons/bouncing_round_button/bouncing_round_button';

import { styles } from './contacts_card_styles';
import { translations } from './contacts_card_translations';
import { ContactAuthorCredits } from '../../../../../../commons/contacts/contact_author_credits/contact_author_credits';

const useStyles = createUseStyles(styles);

export const ContactCard = ({
    name,
    contactRedirectUrl,
    contactImageUrl,
    contactUrl,
    imageEditable,
    additionalActions,
    onImageEditClick,
    onChange,
    onRemove,
    error
}) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    const [input, setInput] = useState(name);
    const [url, setUrl] = useState(contactRedirectUrl);
    const [viewUrl, setViewUrl] = useState(contactUrl);

    const handleTextFieldChange = useCallback((event) => {
        const {
            target: { value }
        } = event;
        setInput(value);
        onChange('name')(value);
    }, []);

    const handleUrlFieldChange = useCallback((event) => {
        const {
            target: { value }
        } = event;
        setUrl(value);
        onChange('contactRedirectUrl')(value);
    }, []);

    const handleViewUrlFieldChange = useCallback((event) => {
        const {
            target: { value }
        } = event;
        setViewUrl(value);
        onChange('contactUrl')(value);
    }, []);

    useEffect(() => {
        setInput(name);
        setUrl(url);
        setViewUrl(viewUrl);
    }, [name,url,viewUrl]);

    return (
        <>
            <Card className={classes.container}>
                {(imageEditable || additionalActions) && (
                    <div className={classes.actions}>
                        {imageEditable && contactImageUrl && (
                            <BouncingRoundButton
                                title={
                                    <FormattedMessage
                                        id="ContactsEditDialog.contactsCard.changeContact"
                                        defaultMessage="Update this contact"
                                    />
                                }
                                onClick={onImageEditClick}
                            />
                        )}
                        {additionalActions}
                    </div>
                )}
                <CardTopHalf
                    error={error}
                    contactImageUrl={contactImageUrl}
                    name={name}
                    onImageEditClick={onImageEditClick}
                    classes={classes}
                />
                <div className={classes.content}>
                    <TextField
                        classes={{ container: classes.textField }}
                        fullWidth
                        placeholder={formatMessage(translations.contactNamePlaceholder)}
                        variant="flat"
                        value={input}
                        onChange={handleTextFieldChange}
                    />
                    <TextField
                        classes={{ container: classes.textField }}
                        fullWidth
                        placeholder={formatMessage(translations.contactUrlPlaceholder)}
                        variant="flat"
                        value={url}
                        onChange={handleUrlFieldChange}
                    />
                    <TextField
                        classes={{ container: classes.textField }}
                        fullWidth
                        placeholder={formatMessage(translations.contactViewUrlPlaceholder)}
                        variant="flat"
                        value={viewUrl}
                        onChange={handleViewUrlFieldChange}
                    />
                    {error?.name && (
                        <Typography color="danger" component="div" variant="helper">
                            {error?.name}
                        </Typography>
                    )}
                </div>
                <PopperCardActions>
                    <Button color="danger" size="small" onClick={onRemove}>
                        <FormattedMessage id="Main.lang.remove" defaultMessage="Remove" />
                    </Button>
                </PopperCardActions>
            </Card>
        </>
    );
};

const CardTopHalf = ({ error, contactImageUrl, contactsUser, classes, name, onImageEditClick }) => {
    if (!contactImageUrl) {
        return (
            <div className={classes.addContactButtonContainer}>
                <Button
                    classes={{
                        container: classes.addContactButton
                    }}
                    color="primary"
                    variant="outlined"
                    onClick={onImageEditClick}
                >
                    <FormattedMessage id="ContactsEditDialog.contactsCard.addContact" defaultMessage="Add a contact" />
                </Button>
            </div>
        );
    }
    return (
        <div className={classes.imageContainer}>
            {error?.contactImageUrl && (
                <Typography color="danger" variant="p">
                    {error?.contactImageUrl}
                </Typography>
            )}
            <img className={classes.image} src={contactImageUrl} alt={name} />
            {contactsUser && <ContactAuthorCredits user={contactsUser} />}
        </div>
    );
};
