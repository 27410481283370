import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './App';
import { NotFound } from './package/components/notfound/notfound';

import './package/styles/global.css';
import './styles/animations.css';
import { DEFAULT_THEME } from '@welovedevs/ui/styles/theme';
import { createTheme, StylesProvider as MuiStylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { create } from 'jss';
import { JssProvider } from 'react-jss';
import jssDefaultPreset from 'jss-preset-default';
import { Provider } from "react-redux";
import { basicReduxStore } from "./package/auth/reduxStore";
import { AzureAD } from 'react-aad-msal';
import { authProvider } from './package/auth/MsalAuthProvider';
import { IntlProvider } from 'react-intl';
import UserDataService from './package/services/UserDataService';

const muiInstance = create(jssDefaultPreset());
muiInstance.setup({ insertionPoint: 'mui-insertion-point' });
const jssinstance = create(jssDefaultPreset());
jssinstance.setup({ insertionPoint: 'jss-insertion-point' });

export const theme = createTheme({
    ...DEFAULT_THEME,
    spacing: 8,
    palette: Object.entries(DEFAULT_THEME.palette).reduce((acc, [name, values]) => {
        const accCopy = acc;
        accCopy[name].main = values[500];
        return accCopy;
    }, DEFAULT_THEME.palette)
});

const jssStyleNode = document.createComment('insertion-point-jss');
const muiStyleNode = document.createComment('mui-insertion-point');
document.head.insertBefore(jssStyleNode, document.head.firstChild);
document.head.insertBefore(muiStyleNode, document.head.firstChild);

const userCurrentLocale = UserDataService.GetLocale();
const isAdmin = window.location.pathname.toLowerCase() === "/admin";

ReactDOM.render(
    <MuiStylesProvider jss={muiInstance}>
        <JssProvider jss={jssinstance}>
            <ThemeProvider {...{ theme }}>
                <Provider store={basicReduxStore}>
                    <BrowserRouter>
                        <IntlProvider locale={userCurrentLocale} messages={UserDataService.GetMessages(userCurrentLocale)}>
                            <Routes>
                                <Route key={"Admin"} exact path="/Admin" element={
                                     isAdmin ?
                                    <AzureAD provider={authProvider} reduxStore={basicReduxStore} forceLogin={isAdmin}>
                                        <App mode={"edit"} provider={authProvider} />
                                    </AzureAD>
                                    : <div/>
                                } />
                                <Route key={"FileNotFound"} exact path="/404" element={<NotFound />} />
                                {UserDataService.GetData(userCurrentLocale).projects.map((project) => (
                                    <Route key={project.id} exact path={`/Projects/${project.id}`} element={<App mode={"readOnly"} provider={authProvider} />} />
                                ))}
                                <Route key={"PathNotFound"} exact path="/*" element={<NotFound />} />
                                <Route key={"ReadOnly"} exact path="/" element={<App mode={"readOnly"} provider={authProvider} />}/>
                            </Routes>
                        </IntlProvider>
                    </BrowserRouter>
                </Provider>
            </ThemeProvider>
        </JssProvider>
    </MuiStylesProvider>,
    document.getElementById('root')
);