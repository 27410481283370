import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';

import { TextField, Typography } from '@welovedevs/ui';
import { useFormikContext } from 'formik';
import { styles } from './project_dialog_content_link_styles';
import { getHexFromPaletteColor } from '../../../../../../utils/styles/styles_utils';
import { Button } from '@material-ui/core';

import './link_button.css';

const useStyles = createUseStyles(styles);

const ProjectDialogContentLinkComponent = ({ link, isEditing }) => {
    const theme = useTheme();
    const color = getHexFromPaletteColor(theme, 'dark');
    const classes = useStyles({ isEditing });
    return (
        <div className={classes.container}>
            <Content link={link} isEditing={isEditing} classes={classes} color={color} />
        </div>
    );
};

const Content = ({ link, isEditing, classes, color }) => {
    if (isEditing) {
        return <EditingContent link={link} classes={classes} />;
    }
    return <DefaultContent link={link} classes={classes} color={color} />;
};

const DefaultContent = ({ link, classes, color }) => (
    <Typography variant="h2" component="h3" classes={{ container: classes.typography }}>
        <Button size="large" key={"ProjectDialogLinkButton"} href={link} className="LinkButton" style={{ backgroundColor: color}}>
            <FormattedMessage id="Project.dialog.link.visit" defaultMessage="Visit Project" />
        </Button>
    </Typography>
);

const EditingContent = ({ classes }) => {
    const { handleChange, values, errors } = useFormikContext();

    return (
        <>
            <Typography variant="label" component="div">
                <FormattedMessage id="Project.dialog.link.title" defaultMessage="Project's list" />
            </Typography>
            <TextField
                fullWidth
                variant="flat"
                onChange={handleChange}
                name="link"
                value={values.link}
                classes={{
                    container: classes.textField
                }}
            />
            {errors?.link && (
                <Typography color="danger" variant="helper" component="p">
                    {errors.link}
                </Typography>
            )}
        </>
    );
};

export const ProjectDialogContentLink = ProjectDialogContentLinkComponent;
