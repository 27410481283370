import { Button } from '@welovedevs/ui';

import { ReactComponent as BrazilFlagLogo } from '../../assets/icons/brazilflag.svg';
import { ReactComponent as UsaFlagLogo } from '../../assets/icons/usaflag.svg';

const LanguageButton = ({ onChangeLanguage, locale }) => {
    let newLocale = locale === 'br' ? 'en' : 'br';

    var flags = {
        en: <UsaFlagLogo/>,
        br: <BrazilFlagLogo/>
      };

    return (
        <>
            <Button style={{padding:'0'}} color={'light'} onClick={() => onChangeLanguage(newLocale)}>
                {flags[newLocale]}
            </Button>
        </>
    );
};

export {LanguageButton};