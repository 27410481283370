import { useCallback, useState, useEffect } from 'react';
import isArray from 'lodash.isarray';
import mergeWith from 'lodash.mergewith';
import omit from 'lodash.omit';
import cloneDeep from 'lodash.clonedeep';
import { Button } from '@welovedevs/ui';

import DeveloperProfile from './package';
import { ReactComponent as SaveIcon } from './package/assets/icons/drop_file_fill.svg';
import { ResumeButton } from './package/components/buttons/resume';
import { LanguageButton } from './package/components/buttons/language';
import { ContactButton } from './package/components/buttons/contact';
import LogService from './package/services/LogService';
import UserDataService from './package/services/UserDataService';
import { ConfigButton } from './package/components/buttons/config';
import { AppConfig } from './data/Config';

const mergeFunction = (objValue, srcValue) => {
    if (!objValue || isArray(objValue)) {
        return srcValue;
    }
    return undefined;
};

function App(props) {
    const mode = props.mode || 'readOnly';
    const [locale, setLocale] = useState(UserDataService.GetLocale());
    const [data, setData] = useState(omit(UserDataService.GetData(locale), 'resumeCustomization'));
    const [customization, setCustomization] = useState(UserDataService.GetData(locale).resumeCustomization || {});

    useEffect(() => {
        async function startup(lastLocale) {
            await LogService.LogIp('ViewPortfolio');
            await getData(lastLocale, false);

            await UserDataService.InitToken(props.provider, mode);
            UserDataService.InitMode(mode);
        }
        let lastLocale = UserDataService.GetLocale();
        startup(lastLocale);
        localStorage.setItem('UserCurrentLocale', lastLocale);
    }, []);

    async function getData(lang, forceChange) {
        var apiData = await UserDataService.GetApiData(lang);
    
        if(forceChange || apiData.lastUpdatedAt !== UserDataService.GetData(lang).lastUpdatedAt){
            setData(omit(apiData, 'resumeCustomization'));
            setCustomization(apiData.resumeCustomization || {});
        }
    }

    async function onChangeLanguage(newLocale) {
        UserDataService.SetLanguage(newLocale);
        setLocale(newLocale);
        await getData(newLocale, true);
    }

    const onEdit = useCallback((newData) => setData(mergeWith(cloneDeep(data), newData, mergeFunction)), [
        JSON.stringify(data)
    ]);

    const onCustomizationChanged = useCallback(setCustomization, [data]);

    const onSaveClick = useCallback(async () => {
        await UserDataService.SetApiData(props.provider, data, customization, locale);
    }, [JSON.stringify(data), JSON.stringify(customization)]);

    return (
        <DeveloperProfile
            mode={mode}
            data={data}
            onEdit={onEdit}
            onCustomizationChanged={onCustomizationChanged}
            options={{
                locale: locale,
                // side: 'back',
                apiKeys: {
                    giphy: process.env.REACT_APP_GIPHY
                },
                endpoints: {
                    devicons: AppConfig.FirebaseStorage
                },
                // dismissFooter : true,
                // showContactInfos: true,
                // maxSkills: 6,
                customization,
                disableSortableExperience: false,
                maxCardsPerRow: 3
            }}
            additionalNodes={{
                banner: {
                    actionsButtons: mode === 'edit' && (
                        <>
                            <ConfigButton locale={locale}/>
                            <LanguageButton onChangeLanguage={onChangeLanguage} locale={locale} />
                            <Button variant="outlined" onClick={onSaveClick} color={'light'} style={{ padding: '0', background:'white' }}>
                                <SaveIcon  />
                            </Button>
                        </>
                        
                    ) ||
                    (
                        <>
                            <ContactButton isButton={true} locale={locale}/>
                            <ResumeButton locale={locale} />
                            <LanguageButton onChangeLanguage={onChangeLanguage} locale={locale} />
                        </>
                    )
                }
            }}
        />
    );
}

export default App;