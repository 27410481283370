import uuid from 'uuid/v4';

export const mapContactsFromJsonResume = (jsonResume) => ({
    contacts: jsonResume?.contacts?.map((contact, index) => ({
        ...contact,
        // generating uuid for manipulating data if not present
        id: contact.id || uuid(),
        index
    }))
});

export const mapContactsToJsonResume = (data) => ({
    contacts: data.contacts
});
