import './notfound_styles.css';
import { useIntl } from 'react-intl';
import { AppConfig } from '../../../data/Config';

const NotFoundComponent = () => {
    const intl = useIntl();

    return (
        <div className="body">
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>{intl.formatMessage({id: "NotFound.Oops"})}</h1>
                    </div>
                    <h2>{intl.formatMessage({id: "NotFound.404"})}</h2>
                    <p>{intl.formatMessage({id: "NotFound.Message"})}</p>
                    <a href={`${AppConfig.BackendApiUrl}`}>{intl.formatMessage({id: "NotFound.ButtonText"})}</a>
                </div>
            </div>
        </div>
    );
};

export const NotFound = NotFoundComponent;