import React, { useCallback } from 'react';

import { createUseStyles } from 'react-jss';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { FormattedMessage } from 'react-intl';
import { ContactCard } from '../contact_card/contacts_card';
import { BouncingRoundButton } from '../../../../../../commons/bouncing_round_button/bouncing_round_button';

import { ReactComponent as MoveIcon } from '../../../../../../../assets/icons/move.svg';

import { styles } from './contacts_sortable_cards_styles';

const useStyles = createUseStyles(styles);

const ContactsSortableCardsComponent = ({
    items = [],
    contactDeleted,
    contactChanged,
    errors,
    setSelectedIndex,
    onSortEnd
}) => {
    const classes = useStyles();
    return (
        <SortableContactsCards
            useDragHandle
            axis="xy"
            items={items}
            contactDeleted={contactDeleted}
            contactChanged={contactChanged}
            errors={errors}
            setSelectedIndex={setSelectedIndex}
            onSortEnd={onSortEnd}
            classes={classes}
        />
    );
};

const SortableContactsCards = SortableContainer(
    ({ items = [], contactDeleted, contactChanged, errors, setSelectedIndex, classes }) => (
        <ul className={classes.list}>
            {items
                .filter(Boolean)
                .sort(({ index: a }, { index: b }) => a - b)
                .map((contact, index) => (
                    <SortableContactItem
                        index={index}
                        key={`contact_${contact.id}_${index}`}
                        onChange={contactChanged}
                        onRemove={contactDeleted}
                        setSelectedIndex={setSelectedIndex}
                        id={contact.id}
                        contact={contact}
                        error={errors?.[index]}
                        contactIndex={index}
                        classes={classes}
                    />
                ))}
        </ul>
    )
);

const DragHandle = SortableHandle(() => (
    <BouncingRoundButton
        title={<FormattedMessage id="ContactsEditDialog.contactCard.dragContact" defaultMessage="Hold me to drag this card!" />}
        icon={MoveIcon}
        tooltipPlacement="bottom"
    />
));

const SortableContactItem = SortableElement(
    ({ id, contact, onChange, onRemove, error: fieldErrors, contactIndex: index, setSelectedIndex, classes }) => {
        const handleRemove = useCallback(() => onRemove(id), [id, onRemove]);
        const handleChange = useCallback((field) => (value) => onChange(index, field, value), [onChange]);
        const handleImageEditClick = useCallback(() => setSelectedIndex(index), []);
        return (
            <li className={classes.listItem}>
                <ContactCard
                    imageEditable
                    contactRedirectUrl={contact?.contactRedirectUrl}
                    contactImageUrl={contact?.contactImageUrl}
                    contactUrl={contact?.contactUrl}
                    name={contact?.name}
                    onChange={handleChange}
                    onRemove={handleRemove}
                    onImageEditClick={handleImageEditClick}
                    error={fieldErrors}
                    additionalActions={<DragHandle />}
                />
            </li>
        );
    }
);

export const ContactsSortableCards = ContactsSortableCardsComponent;
