import { Button } from '@welovedevs/ui';
import { createUseStyles } from 'react-jss';
import { Twemoji } from 'react-emoji-render';
import { useCallbackOpen } from '../../../hooks/use_callback_open';
import { CustomizeDialog } from '../customize_dialog/customize_dialog';
import { styles } from './customize_button_styles';

const useStyles = createUseStyles(styles);
export const CustomizeButton = ({ customizationOptions }) => {
    const [dialogOpen, open, close] = useCallbackOpen();
    const classes = useStyles();

    return (
        <>
            <CustomizeDialog open={dialogOpen} onClose={close} customizationOptions={customizationOptions} />
            <Button style={{ background:'white', height:'41px', width:'72px' }} color="light" onClick={open}>
                <Twemoji svg text="🎨" className={classes.icon} />
            </Button>
        </>
    );
};
