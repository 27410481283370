import { createScreenWidthMediaQuery } from '../../../../utils/styles/styles_utils';

export const styles = (theme) => ({
    icon: {
        marginRight: theme.miscellaneous.spacing,
        width: 14
    },
    penIcon: {
        extend: 'icon',
        fill: '#00f'
    },
    penIconFill: {
        extend: 'icon',
        fill: '#00f',
        transform: 'scale(1.5)',
        marginRight: '0px'
    },
    checkIcon: {
        extend: 'icon',
        '& g': {
            stroke: theme?.palette?.light?.[500] ?? '#fff'
        }
    },
    checkIconFill: {
        extend: 'icon',
        '& g': {
            stroke: '#2f2',
            strokeWidth: '3'
        },
        fill: '#2f2',
        transform: 'scale(1.8)',
        marginRight: '0px'
    },
    [createScreenWidthMediaQuery('max-width', theme.screenSizes.small)]: {
        penIcon: {
            marginRight: 'unset'
        },
        checkIcon: {
            marginRight: 'unset'
        }
    }
});
